const canvas = document.getElementById('gameCanvas');
const ctx = canvas.getContext('2d');

// Éléments de statut bonus/malus
const statusDisplay = document.getElementById('status');

let startPlayer = {
    x: 10,
    y: 750,
};

// Variables du joueur
let player = {
    x: startPlayer.x,
    y: startPlayer.y,
    width: 50,
    height: 50,
    color: 'red',
    dy: 0,
    dx: 8,  // Vitesse de déplacement horizontal
    gravity: .75,
    jumpPower: -10,
    isJumping: false,
    hasJumped: false,
    hasDoubleJumped: false,
    hasLanded: false,  // Pour vérifier si le joueur retouche le sol
};

// Variables du sol et des trous
let platforms = [
    { x: 0, width: 350 },  // Sol initial
    { x: 800, width: 300 }, // Deuxième plateforme
];
let platformHeight = 50;
let platformGap = 100;

// Variables du jeu
let score = 0;
let isGameOver = false;
let frameCount = 0;
let finishLine = 1024 - 50; // Position de la ligne d'arrivée
let gameStarted = false; // Nouvelle variable pour suivre l'état du jeu

// Variables pour la musique
const backgroundMusic = new Audio('assets/sounds/background-music.mp3'); // Musique de l'écran d'accueil
const backgroundMusicGame = new Audio('assets/sounds/background-music-game.mp3'); // Musique pendant le jeu

// Variables pour les sons des sauts
const jumpSlimeSound = new Audio('assets/sounds/jump-slime.mp3');
const jumpWhooshSound = new Audio('assets/sounds/jump-whoosh.mp3');

// Son lorsqu'il touche le sol après un saut
const landSound = new Audio('assets/sounds/land.mp3');

// Son pour la ligne d'arrivée
const finishSound = new Audio('assets/sounds/finish.mp3');

// **Nouveaux sons pour le deuxième saut**
const bonusSound = new Audio('assets/sounds/bonusSound.mp3');  // Son du bonus du deuxième saut
const malusSound = new Audio('assets/sounds/malusSound.mp3');  // Son du malus du deuxième saut

// Variables UI
const scoreDisplay = document.getElementById('score');
const gameOverDisplay = document.getElementById('game-over');
const restartBtn = document.getElementById('restart-btn');
const startScreen = document.getElementById('start-screen');
const startBtn = document.getElementById('start-btn');

// Gérer les interactions avec la touche espace
document.addEventListener('keydown', (e) => {
    if (e.code === 'Space') {
        if (!gameStarted) {
            // Si le jeu n'a pas commencé ou qu'il est en game over ou victoire, démarrer ou redémarrer la partie
            if (isGameOver) {
                restartGame(); // Si le jeu est terminé (game over ou victoire), relancer la partie
            } else {
                startGame();   // Si le jeu n'est pas encore commencé, lancer la partie
            }
        } else {
            // Sinon, gérer le saut du joueur
            if (!player.isJumping && !player.hasJumped) {
                // Premier saut
                player.dy = player.jumpPower;
                player.isJumping = true;
                player.hasJumped = true;
                player.hasLanded = false;  // Réinitialiser l'état de l'atterrissage
            } else if (player.hasJumped && !player.hasDoubleJumped) {
                // Deuxième saut avec bonus/malus aléatoire
                let effect = Math.random(); // Générer un nombre aléatoire entre 0 et 1

                if (effect < 0.5) {
                    // Bonus : boost du saut
                    player.dy = player.jumpPower * 1.5; // Augmentation de la puissance du saut
                    jumpWhooshSound.play(); // Jouer le son du saut bonus
                    bonusSound.play(); // Jouer le son spécifique pour le bonus du double saut
                    statusDisplay.textContent = 'Bonus: Boost du saut !';  // Afficher le statut
                    statusDisplay.style.color = 'green'; // Couleur verte pour le bonus
                    console.log("Bonus: Boost du saut !");
                } else {
                    // Malus : ralentissement du saut
                    player.dy = player.jumpPower * 0.5; // Réduction de la puissance du saut
                    jumpSlimeSound.play(); // Jouer le son du saut malus
                    malusSound.play(); // Jouer le son spécifique pour le malus du double saut
                    statusDisplay.textContent = 'Malus: Ralentissement du saut !'; // Afficher le statut
                    statusDisplay.style.color = 'red'; // Couleur rouge pour le malus
                    console.log("Malus: Ralentissement du saut !");
                }

                player.hasDoubleJumped = true; // Le deuxième saut est utilisé
            }
        }
    }
});

// Fonction pour jouer la musique en boucle
function playMusicBackground() {
    backgroundMusic.loop = true;
    backgroundMusic.play(); // Musique de l'écran d'accueil
}

function playMusicGame() {
    backgroundMusicGame.loop = true;
    backgroundMusicGame.play(); // Musique pendant le jeu
}

// Fonction pour arrêter la musique
function stopMusicBackground() {
    backgroundMusic.pause();
    backgroundMusic.currentTime = 0;  // Réinitialiser la position de la musique d'accueil
}

function stopMusicGame() {
    backgroundMusicGame.pause();
    backgroundMusicGame.currentTime = 0;  // Réinitialiser la position de la musique du jeu
}

// Fonction pour lancer le jeu
function startGame() {
    statusDisplay.textContent = 'Statut: Neutre';  // Réinitialiser le statut
    statusDisplay.style.color = 'white'; // Couleur neutre au premier saut
    startScreen.style.display = 'none'; // Masquer l'écran d'accueil
    canvas.style.display = 'block';     // Afficher le canvas
    stopMusicBackground();              // Arrêter la musique de l'écran d'accueil
    playMusicGame();                    // Jouer la musique du jeu
    gameStarted = true;                 // Indiquer que la partie est lancée
    gameLoop();                         // Lancer la boucle de jeu
}

// Mettre à jour la logique du jeu
function update() {
    if (isGameOver) return;

    frameCount++;

    // Appliquer la gravité au joueur
    player.dy += player.gravity;
    player.y += player.dy;

    // Déplacer le joueur vers la droite
    player.x += player.dx;

    // Déclencher le game over si le joueur touche la ligne y = 0 (sort de l'écran par le haut)
    if (player.y <= 0) {
        gameOver();
        return;
    }

    // Vérifier si le joueur atteint la ligne d'arrivée
    if (player.x >= finishLine) {
        gameWon();
        return;
    }

    // Vérifier si le joueur est sur une plateforme
    let onPlatform = false;
    platforms.forEach(platform => {
        if (
            player.x + player.width > platform.x && // Le joueur entre dans la plateforme
            player.x < platform.x + platform.width && // Le joueur n'a pas dépassé la plateforme
            player.y + player.height >= canvas.height - platformHeight // Le joueur atteint la hauteur du sol
        ) {
            // Le joueur est sur la plateforme, on stoppe la chute
            player.y = canvas.height - platformHeight - player.height;
            player.dy = 0;
            player.isJumping = false;

            if (!player.hasLanded) {
                landSound.play();  // Jouer le son d'atterrissage
                player.hasLanded = true;  // Indiquer que le joueur a atterri
            }

            onPlatform = true;
        }
    });

    // Si le joueur n'est pas sur une plateforme, il continue de tomber
    if (!onPlatform) {
        player.isJumping = true;  // Le joueur est en "chute libre" dans le trou
    }

    // Si le joueur tombe sous le sol (hors écran), c'est game over
    if (player.y + player.height >= canvas.height) {
        gameOver();
    }

    // Mettre à jour le score en fonction du déplacement
    score = Math.floor(player.x / 10); // Score basé sur la distance parcourue
    scoreDisplay.textContent = `Score: ${score}`; // Affichage du score

    draw();
}

// Dessiner le joueur, les plateformes, et le score
function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Dessiner le sol
    ctx.fillStyle = 'green';
    platforms.forEach(platform => {
        ctx.fillRect(platform.x, canvas.height - platformHeight, platform.width, platformHeight);
    });

    // Dessiner la ligne d'arrivée
    ctx.fillStyle = 'yellow';
    ctx.fillRect(finishLine, canvas.height - (platformHeight * 2), 50, platformHeight);

    // Dessiner le joueur
    ctx.fillStyle = player.color;
    ctx.fillRect(player.x, player.y, player.width, player.height);

    // Afficher le score (déjà fait dans update())
}

// Gérer le Game Over
function gameOver() {
    isGameOver = true;
    gameOverDisplay.textContent = "Game Over";
    gameOverDisplay.style.display = 'block';
    restartBtn.style.display = 'block';
    stopMusicGame(); // Arrêter la musique au game over
    gameStarted = false; // Réinitialiser l'état du jeu pour le restart
}

// Gérer la victoire
function gameWon() {
    isGameOver = true;
    gameOverDisplay.textContent = "Félicitations !";
    gameOverDisplay.style.display = 'block';
    restartBtn.style.display = 'block';
    stopMusicGame(); // Arrêter la musique en cas de victoire
    finishSound.play(); // Jouer le son de l'arrivée
    gameStarted = false; // Réinitialiser l'état du jeu après victoire
}

// Relancer le jeu
function restartGame() {
    isGameOver = false;
    gameOverDisplay.style.display = 'none';
    restartBtn.style.display = 'none';
    statusDisplay.textContent = 'Statut: Neutre';  // Réinitialiser le statut
    statusDisplay.style.color = 'white'; // Couleur neutre au premier saut
    player.x = startPlayer.x;
    player.y = startPlayer.y;
    player.dy = 0;
    score = 0;
    frameCount = 0;
    player.hasJumped = false;
    player.hasDoubleJumped = false;
    player.hasLanded = false;
    playMusicGame(); // Rejouer la musique du jeu lors du redémarrage
    gameStarted = true; // Réinitialiser l'état de jeu
    gameLoop();
}

restartBtn.addEventListener('click', restartGame);
startBtn.addEventListener('click', startGame); // Démarrer le jeu lorsque le bouton "Commencer la Partie" est cliqué

// Boucle de jeu
function gameLoop() {
    update();
    if (!isGameOver) {
        requestAnimationFrame(gameLoop);
    }
}

window.onload = function () {
    // Lancer la musique de l'écran d'accueil
    playMusicBackground();
};